<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Implantation des essais</h2>
    </Container>
  </div>

  <Input id="bpe" type="hidden" ref="bpe" style="display: none" />

  <div class="key-values key-values--footer-bottom">
      <KeyValue
        v-if="isShared"
        table
        label="Cibles"
      >
        <template v-if="protocol.cibles && protocol.cibles.length > 0">
          <div class="tags">
            <MiniTag
              v-for="cible in protocol.cibles"
              :key="cible.id"
              :id="cible.uid"
              :text="cible.valeur"
            />
          </div>
        </template>
        <template v-else>
          <span>Non renseigné</span>
        </template>
      </KeyValue>
    <KeyValue v-else table label="Cibles" type="input">
      <SelectExtended
        v-if="form.references.cible"
        id="cibles"
        chips
        multiple
        optionKey="id"
        optionValue="valeur"
        :apiEndpoint="`dictionnaire/referentiel/${form.references.cible.id}/valeur`"
        :apiParams="{ sort: 'valeur.ASC' }"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Situation à favoriser"
      :value="protocol.situation_a_favoriser"
    />
    <KeyValue
      v-else-if="isBpe"
      table
      label="Situation à favoriser"
      type="input"
      required
    >
      <Input id="situation_a_favoriser" />
    </KeyValue>
    <KeyValue v-else table label="Situation à favoriser" type="input">
      <Input id="situation_a_favoriser" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Dispositif expérimental"
      :value="protocol.type_dispositif?.designation"
    />
    <KeyValue v-else table label="Dispositif expérimental" type="input">
      <SelectExtended
        id="typedispositif_uid"
        optionKey="uid"
        optionValue="designation"
        :apiParams="{ sort: 'designation.ASC', limit: 0,  filters: 'uid:nin(MANUEL)' }"
        :apiEndpoint="`dictionnaire/type/dispositif`"

      />
    </KeyValue>

    <KeyValue
      v-if="isBpe"
      table
      label="Nombre de répétitions"
      type="input"
      required
    >
      <Input id="nombre_blocs" type="number" />
    </KeyValue>
    <KeyValue v-else table label="Nombre de répétitions" type="input">
      <Input id="nombre_blocs" type="number" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Surface minimum des microparcelles"
      :value="protocol.surface_microparcelles ? `${protocol.surface_microparcelles} m²` : null"
    />
    <KeyValue
      v-else-if="isBpe"
      table
      label="Surface minimum des microparcelles"
      type="input"
      required
    >
      <Input
        id="surface_microparcelles"
        type="number"
        inputAfter="m²"
        :options="{
          min: 0,
          step: 0.05,
        }"
      />
    </KeyValue>
    <KeyValue
      v-else
      table
      label="Surface minimum des microparcelles"
      type="input"
    >
      <Input
        id="surface_microparcelles"
        type="number"
        inputAfter="m²"
        :options="{
          min: 0,
          step: 0.05,
        }"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Implantation des témoins"
      :value="protocol?.implantation_temoins?.valeur"
    />
    <KeyValue
      v-else-if="isBpe"
      required
      table
      label="Implantation des témoins"
      type="input"
    >
      <SelectExtended
        v-if="form.references.implantationtemoins"
        id="implantation_temoins_id"
        optionKey="id"
        optionValue="valeur"
        :apiEndpoint="`dictionnaire/referentiel/${form.references.implantationtemoins.id}/valeur`"
      />
    </KeyValue>
    <KeyValue v-else table label="Implantation des témoins" type="input">
      <SelectExtended
        v-if="form.references.implantationtemoins"
        id="implantation_temoins_id"
        optionKey="id"
        optionValue="valeur"
        :apiEndpoint="`dictionnaire/referentiel/${form.references.implantationtemoins.id}/valeur`"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Secteur géographique"
      :value="protocol.secteur_geographique"
    />
    <KeyValue
      v-else-if="isBpe"
      table
      label="Secteur géographique"
      type="input"
      required
    >
      <Input id="secteur_geographique" />
    </KeyValue>
    <KeyValue v-else table label="Secteur géographique" type="input">
      <Input id="secteur_geographique" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Orientation par rapport au sens du vent"
      :value="protocol.orientation_semis"
    />
    <KeyValue
      v-else-if="isBpe"
      table
      label="Orientation par rapport au sens de semis"
      type="input"
      required
    >
      <Input id="orientation_semis" />
    </KeyValue>
    <KeyValue
      v-else
      table
      label="Orientation par rapport au sens de semis"
      type="input"
    >
      <Input id="orientation_semis" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Type de sol"
      :value="protocol.type_sol"
    />
    <KeyValue v-else-if="isBpe" table label="Type de sol" type="input" required>
      <Input id="type_sol" />
    </KeyValue>
    <KeyValue v-else table label="Type de sol" type="input">
      <Input id="type_sol" />
    </KeyValue>
    <KeyValue table label="Commentaires" type="input">
      <Input id="commentaires" type="textarea"/>
    </KeyValue>
  </div>
</template>

<script>
import SelectExtended from '@/components/form/SelectExtended.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Container from '@/components/layout/Container.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'ExperimentImplantationAddEditSubview',

  components: {
    MiniTag,
    SelectExtended,
    KeyValue,
    Input,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0].concat(validationSchema[1])
    const { isSubmitting, errors } = this.formService.initFrom(vs)
    return {
      protocol: {
        bpe: false,
      },
      form: {
        references: [],
        thematiques: [],
      },
      sousThemesEndpoint: 'dictionnaire/sous-thematique',
      componentKey: 0,
      step: 2,
      routeName: this.$route.name,
      previousRouteName: null,
      nextRouteName: null,
      vs,
      isSubmitting,
      errors,
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
    'form-submitted',
    'set-submitted',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe: {
      get() {
        return this.protocol.bpe ?? false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
    isShared() {
      return (
        this.protocol.date_partage !== null && this.protocol.partage_accepte
      )
    },
  },

  watch: {
    modelValue(newVal) {
      // watch it
      if (newVal) {
        const { values } = this.formService
        const schema = this.protocolService.getSchema()
        const datas = {}

        Object.keys(this.vs.fields).forEach((node) => {
          if (node in values) {
            datas[node] = values[node]
          } else if (node in schema) {
            datas[node] = datas[node] ? datas[node] : schema[node]
          }
        })

        this.formService.setValues(datas)

        this.formService.validate(this.formService.values).then((errors) => {
          if (errors.valid) {
            this.$emit('form-submitted', this.formService.values)
          } else {
            this.formService.handleErrors(true)
            this.$emit('set-submitted')
          }
        })
      }
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}
    this.form.references = await this.productService.getReferenceByUids(
      'cible',
      'implantationtemoins',
    )

    if (this.$route.params.id) {
      if (this.protocol.thematique) {
        this.ste = this.protocol.thematique
      }
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolGeneralInformationEdit'
      this.nextRouteName = 'protocolStudiedExperimentEdit'
    } else {
      this.previousRouteName = 'protocolGeneralInformationAdd'
      this.nextRouteName = 'protocolStudiedExperimentAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: this.nextRouteName,
      routeParams,
    })

    this.protocol = await this.getProtocol()
    this.$emit('populate', this.protocol)
    this.emitter.emit('close-loader')
  },

  methods: {
    async getProtocol() {
      const response = await this.fetchService.get(
        `protocole/${this.$route.params.id}`,
      )
      const protocol = await response.data
      this.$emit('populate', protocol)
      this.isBpe = protocol

      return protocol
    },
  },
}
</script>

<style scoped>
</style>
